import React from 'react'
import Empower from './Empower'
import GlobalMarket from './GlobalMarket'
import GreatThings from './GreatThings'
import Investors from './Investors'
import Profile from './Profile'
import Youtube from './Youtube'
import AndelaFooter from './Footer'
import Herosss from './Herosss'

const AboutComp = () => {
  return (
    <div>
        <Herosss  />
    <Empower />
    <Profile />
    <Investors />
    <Youtube />
    <GreatThings />
    <GlobalMarket />
    <AndelaFooter />

    </div>
  )
}

export default AboutComp